import React, { Component } from 'react';
import BreadCrumb from "../../components/Navs/Breadcrumb"
import {
  Box,
  Grid,
  Button,
  Typography
} from '@material-ui/core';
import Card from '../../components/Card/Card.jsx';
import Table from '../../components/Table/Table.jsx';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import HtmlParser from 'react-html-parser';
import Icon from '@material-ui/core/Icon';
import LDSIcon from '../../assets/img/services-icon2.png';
import apiUtil from "../../api/apiUtil.jsx";
import Modal from 'react-awesome-modal';
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import defaultImg from '../../assets/img/default_image.jpg';

class AllAccount extends Component {
  static contextType = AuthContext;
  
  is_master_admin_application = '' 

  constructor(props) {
    super(props);

  this.refTable = React.createRef();
    this.refTableSearch = React.createRef();
    let hiddenColumns = [];
    let columnHiddenSettingsId = 0;
    let columnHiddenSettings = JSON.parse(localStorage.getItem('job_column_settings'));

    if (columnHiddenSettings !== null) {
      hiddenColumns = columnHiddenSettings.json_data.replace(/[^0-9a-zA-Z-_.,]/g, "").split(",");
      columnHiddenSettingsId = columnHiddenSettings.id ? columnHiddenSettings.id : 0;
    }


    this.state = {
      data: [],
      page: 1,
      visible: false,
      total: 0,
      limit: 20,
      delete_id: '',
      disabled: null,
      isLoadingStatus: false,
      isLoading: false,
      pageSize: 20,      // status: [],
      hiddenColumnSettingId: columnHiddenSettingsId,
      hiddenColumns: hiddenColumns,
    }
  }

  openModal(id) {
    this.setState({
      visible: true,
      delete_id: id
    });
  }

  closeModal() {
    this.setState({
      visible: false
    });
  }

  getUserLogo = () => {
    const { user } = this.context;
    return apiUtil.getUserLogo(user, defaultImg);
  }

  getUserinfo = () => {
    const { user } = this.context;
    let userInfo = apiUtil.getObject(user);
    return (userInfo && userInfo.owner_info) ? userInfo.owner_info : null;
  }

  getUserEmail = () => {
    let userInfo = this.getUserinfo();
    return (userInfo && userInfo.email) ? userInfo.email : '';
  }

  componentDidMount() {
    const { user } = this.context;
    let userValue = JSON.parse(user);
    this.is_master_admin_application = userValue.owner_info.is_master_admin_application;
    this.callReadApi();
  }

  callReadApi() {
    let errorMessage = "";
    let status = 0;
    const {
      REACT_APP_AUTH_API_URL
    } = process.env;
    const PATH = 'admin/api/';
    const api = "admins";
    const URL = REACT_APP_AUTH_API_URL + PATH + api;
    const { accessToken } = this.context;

    let param = {
      page: this.state.page,
      take: this.state.limit,
      application_registration_ids: "",
      search: "",
      // start_date: startDate,
      // end_date: endDate,
    };
    this.setState({
      isLoading:true
    })
    axios.get(
      // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
      URL,
      {
        params: param,
        data: param,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken,
          'app-name': 'AUTH',
        }
      })
      .catch(error => {
        try {
          status = error.response.status;

          if (error.response) {
            // Request made and server responded
            errorMessage = error.response.data;
          } else if (error.request) {
            // The request was made but no response was received
            errorMessage = error.request;
          } else {
            // Something happened in setting up the request that triggered an Error
            errorMessage = error.message;
          }
        } catch (err) {
          errorMessage = 'Service Unavailable!'
        }

        if(errorMessage && errorMessage.error) {
          apiUtil.toast(errorMessage.error, 'check_circle', 'error');
        } else {
            apiUtil.toast(errorMessage, 'check_circle', 'error');
        }
        this.setState({
          isLoading:false,
        })
      }).then((response) => {
        // console.log(response);
        if (response != null) {
          this.setState({
            data: response.data.result,
            total: response.data.total,
            isLoading: false,
          });
          //console.log("response sas", this.state.data);
        }

      });
  }

  callDeleteApi(id) {

    let errorMessage = "";
    let status = 0;
    const {
      REACT_APP_AUTH_API_URL
    } = process.env;
    const PATH = 'admin/api/';
    const api = `delete/admins`;
    const URL = REACT_APP_AUTH_API_URL + PATH + api;
    let token = window.localStorage.getItem('access_token');
    let param = {
      id: id,
    };
    axios.delete(
      // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
      URL,
      {
        params: param,
        data: param,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
      .catch(error => {
        try {
          status = error.response.status;

          if (error.response) {
            // Request made and server responded
            errorMessage = error.response.data;
          } else if (error.request) {
            // The request was made but no response was received
            errorMessage = error.request;
          } else {
            // Something happened in setting up the request that triggered an Error
            errorMessage = error.message;
          }
        } catch (err) {
          errorMessage = 'Service Unavailable!'
        }
      }).then((response) => {
        apiUtil.toast('Successfully Deleted !!', 'error');
        this.callReadApi();
      });
  }

  callUpdateApi(id, disabled) {

    let errorMessage = "";
    let status = 0;
    const {
      REACT_APP_AUTH_API_URL
    } = process.env;
    // let dataid = this.state.id;
    const PATH = 'admin/api/';
    const api = `admins/${id}/update`;

    const URL = REACT_APP_AUTH_API_URL + PATH + api;
    let token = window.localStorage.getItem('access_token');
    const data = {
      disabled: !disabled,
    };
    axios.put(
      // "https://auth-api.worknode.ai/admin/api/application_companies?search=&page=1&take=10&sorting_array[]=created_at%20DESC",
      URL,
      data,
      {
        // params: param,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
          'app-name': 'AUTH',
        }
      })
      .catch(error => {
        try {
          status = error.response.status;

          if (error.response) {
            // Request made and server responded
            errorMessage = error.response.data;
          } else if (error.request) {
            // The request was made but no response was received
            errorMessage = error.request;
          } else {
            // Something happened in setting up the request that triggered an Error
            errorMessage = error.message;
          }
        } catch (err) {
          errorMessage = 'Service Unavailable!'
        }
      }).then((response) => {
        this.callReadApi();
      });
  }

  customStickyPageHeader = () => {
    return <Box clone pl={4} pr={4} height={'100%'} alignItems={'center'}>
      <Grid container>
        <BreadCrumb />
      </Grid>
    </Box>
  }

  customTable = () => {

    return <Table
      ref={this.refTable}
      className={'job-table-list'}
      isLoading={this.state.isLoading}
      grouping={this.state.grouping}
      limit={this.state.limit}
      pageSize={this.state.pageSize}
      // pageSizeOptions ={this.state.pageSizeOptions}
      page={this.state.page}
      total={this.state.total}
      data={this.state.data}
      // defaultExpanded={true}
      // selection={true}
      onPageChange={(page, size) => {

        this.state.limit = size;
        this.setState({
          page: page,
        }, () => {
          this.callReadApi();
          // this.callReadApi(this.refTableSearch.current.state.value);
        });
      }}
      // Toolbar={this.customToolbar}
      // Row={this.customRow}
      columns={[

        {
          title: "Name",
          field: "Name",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
          render: (row, type) => {
            if (type === 'row') {
              return (row.first_name != ' ') ? HtmlParser(row.first_name) : '';
            } else {
              return row;
            }
          }
        },
        {
          title: "Email",
          field: "email",
          cellStyle: {
            minWidth: 240,
            maxWidth: 240
          },

          render: (row, type) => {
            if (type === 'row') {
              return (row.email != ' ') ? <span style={{overflowWrap: 'break-word', wordBreak: 'break-all',  display:'inline-block'}}>{HtmlParser(row.email)}</span> : '';
            } else {
              return row;
            }
          }
        },
        {
          title: "Role",
          field: "role",
          cellStyle: {
            minWidth: 180,
            maxWidth: 180
          },
          render: (row, type) => {
            if (type === 'row') {
              if (row.roles.hasOwnProperty('0')) {
                return (row.roles && row.roles[0].role_name != ' ') ? HtmlParser(row.roles[0].role_name) : '';
              } else {
                return 'No Role !!';
              }
            } else {
              return row;
            }
          }
        },
        {
          title: "Status",
          field: "status",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
          render: (row, type) => {
            if (type === 'row') {
              return (row.disabled != false) ? <span style={{ color: 'red' }}>Disable</span> : <span style={{ color: 'green' }}>Active</span>;
            } else {
              return row;
            }
          }
        },
        {
          title: "Action",
          field: "action",
          cellStyle: {
            minWidth: 240,
            maxWidth: 240
          },
          render: (row, type) => {
            if (type === 'row') {
              return <Box>
                {/* <NavLink
                  to={{
                    pathname: "/accounts/add-account",
                    state: { id: row.id } // your data array of objects
                  }} > */}
                  { row.is_master_admin_application ? "Admin" :<>
                  <Button style={ this.is_master_admin_application == true? ((row.roles[0] && row.roles[0].role_name == 'Customer Template Role') ? { backgroundColor: '#dddddd' } : { backgroundColor: '#2e86aa' }) : {backgroundColor: '#d3d3d3'}}
                    onClick={() => {
                      this.props.history.push({
                      pathname: '/accounts/add-account',
                      state: {
                          id: row.id
                      }
                      });
                    }}
                    
                    disabled = { this.is_master_admin_application == true && row.roles[0] && row.roles[0].role_name != 'Customer Template Role' ? false : true}
                 >
                    <Icon style={{ color: "#FFFFFF" }}>edit</Icon></Button>&nbsp;
                <Button style={ this.is_master_admin_application == true? ((row.disabled == true) ? { backgroundColor: '#dddddd' } : { backgroundColor: '#009900' }) : {backgroundColor: '#d3d3d3'}}
                  onClick={() => {
                    this.callUpdateApi(row.id, row.disabled);
                  }}
                  disabled = { this.is_master_admin_application == true? false : true}
                ><Icon style={{ color: "#FFFFFF" }}>power_settings_new</Icon></Button>&nbsp;
                <Button style={ this.is_master_admin_application == true? {  backgroundColor: '#D11A2A'} : {backgroundColor: '#d3d3d3'}}
                  onClick={() => {
                    this.openModal(row.id);
                  }}
                  disabled = { this.is_master_admin_application == true ? false : true}
                ><Icon style={{ color: "#FFFFFF" }}>delete_forever</Icon></Button>
              </>}
              </Box>
            } else {
              return row;
            }
          }
        },
      ]}
    />
  }

  render() {
    return <Box>

      <Card className={'job-listing-step'}>
        <Grid container>
          <Grid item xs={12} md={12} sm={12} lg={6} xl={6} >
            <Box style={{display: 'flex'}}>

              <img src={this.getUserLogo()} alt="LDS Icon" height="100px" />
              <Box p={2}>
                <Typography gutterBottom variant="h5" component="div">
                  Your Account
                </Typography>
                <Typography variant="h6" color="text.secondary">
                  {this.getUserEmail()}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  <NavLink
                    style={{
                      color: "#2E86AA",
                      textDecoration: "none"
                    }}
                    to={{
                      pathname: "/setting-profile",
                    }}>
                    Edit profile
                  </NavLink>
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} sm={12} lg={6} xl={6}>
            <Box style={{display: 'flex'}}>
              <img src={LDSIcon} alt="LDS Icon" height="100px" />
              <Box p={2}>
                <Typography gutterBottom variant="h5" component="div">
                  FMS Account(s)
                </Typography>
                <Typography
                  style={{
                    color: "#2E86AA",
                    textDecoration: "none"
                  }}
                  variant="h6" color="text.secondary">
                  {this.state.total}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Card>

      <Card className={'job-listing-step'}>
        <Grid container>
          <Grid xs={6} lg={6}><h2>List of available users</h2></Grid>
          <Grid xs={6} lg={6} style={{ textAlign: 'right' }}>
            <Button style={{ backgroundColor: '#2E86AA' }}>
              <NavLink
                to={{
                  pathname: "/accounts/add-account",
                  // state : {id:row.id} // your data array of objects
                }}
                style={{ textDecoration: 'none', textTransform: "lowercase", color: "#FFFFFF" }}
              >Add Account</NavLink>
            </Button></Grid>
        </Grid>
        {this.customTable()}
      </Card>

      <Modal visible={this.state.visible} width="400" height="150" effect="fadeInDown" onClickAway={() => this.closeModal()}>
        <div className="modal-group">
          <h1 className="modal-header">Delete!!</h1>
          <p className="modal-title">Are you sure, you want to delete?</p>
          <div className="modal-button">
            <a className="modal-button-close" href="javascript:void(0);" onClick={() => this.closeModal()}>Close</a>
            <a className="modal-button-delete" href="javascript:void(0);"
              onClick={() => {
                this.callDeleteApi(this.state.delete_id);
                this.closeModal();
              }}>Delete</a>
          </div>
        </div>
      </Modal>
    </Box>;
  }
}

export default AllAccount;
